<template>
<div class="selfcheck-container" id="js_selfcheck_container">
	<article class="selfcheck temperaturedifference res">
		<h1 class="selfcheck__header"><img src="~@/assets/img/selfcheck-temperature-difference/title01.png" alt="医師監修 寒暖差疲労セルフチェック presented by 頭痛ーる"></h1>

		<!-- NOTE:ここから公式WEBに流用 -->
		<!-- 結果 -->
		<section class="selfcheck__result" v-if="result === '2'">
			<h2 class="selfcheck__result--title">寒暖差疲労の可能性は<br>高いです</h2>
			<div class="selfcheck__result--img"><img src="~@/assets/img/selfcheck-temperature-difference/result_03.png" alt=""></div>
			<p class="selfcheck__result--text">あなたの体調不良は寒暖差疲労の可能性があります。気温差が大きい日には、自律神経が過剰に働いて不調が現れる可能性がありますので、注意しましょう。自律神経は、気圧や気温の変化で乱れやすくなります。頭痛ーるで事前に気圧や気温の変化を確認してみましょう。</p>
		</section>
		<section class="selfcheck__result" v-else-if="result === '1'">
			<h2 class="selfcheck__result--title">寒暖差疲労の可能性が<br>あります</h2>
			<div class="selfcheck__result--img"><img src="~@/assets/img/selfcheck-temperature-difference/result_02.png" alt=""></div>
			<p class="selfcheck__result--text">あなたの身体は寒暖差疲労になりやすい身体のようです。気温差が大きい日には、自律神経が過剰に働いて不調が現れる可能性がありますので、注意しましょう。自律神経は、気圧や気温の変化で乱れやすくなります。頭痛ーるで事前に気圧や気温の変化を確認してみましょう。</p>
		</section>
		<section class="selfcheck__result" v-else-if="result === '0'">
			<h2 class="selfcheck__result--title">寒暖差疲労の可能性は<br>低いです</h2>
			<div class="selfcheck__result--img"><img src="~@/assets/img/selfcheck-temperature-difference/result_01.png" alt=""></div>
			<p class="selfcheck__result--text">あなたの身体はいまのところ寒暖差疲労になりにくいですが、身体の調子は日々変化します。もし原因不明の体調不良になったら、頭痛ーるで気圧や気温の変化が影響しているか確かめてみましょう。</p>
		</section>
		<section class="selfcheck__result" v-else>
			<h2 class="selfcheck__result--title is-failed">結果の取得に失敗しました。</h2>
		</section>
		<!-- /結果 -->
		<!-- NOTE:ここまで公式WEBに流用 -->

		<!-- 監修者 -->
		<section class="selfcheck__supervisor">
			<h2 class="selfcheck__supervisor--title">
				<img src="~@/assets/img/selfcheck-temperature-difference/supervisor.png" alt="久手堅 司（くでけん つかさ）">
				<span><span class="is-large">監修</span><br>せたがや内科・神経内科クリニック<br>院長・医学博士<br><span class="is-large">久手堅 司（くでけん つかさ）</span></span>
			</h2>
			<p class="selfcheck__supervisor--text">日本内科学会&thinsp;総合内科専門医/日本神経学会&thinsp;神経内科専門医/日本頭痛学会&thinsp;頭痛専門医/日本脳卒中学会&thinsp;脳卒中専門医</p>
		</section>
		<!-- /監修者 -->

		<!-- アウトロ -->
		<section class="selfcheck__outro">
			<h2 class="selfcheck__outro--title">気圧や気温の変化による体調不良「気象病」は、事前に予測ができます。頭痛ーるは、不調の原因となる「気圧の変化」をお知らせします！</h2>
			<div class="growthhack-f__caution">
				<p class="growthhack-f__caution--right">低気圧で体調崩すかも。早めに用事を済ませよう！</p>
				<p class="growthhack-f__caution--left">痛みが出る前に薬を準備しておこう！</p>
			</div>
			<div class="growthhack-f__with-zutool">
				<p class="growthhack-f__with-zutool--title">
					with <span class="is-large">頭痛ーる</span>で<br>気圧と上手に付き合って<br>快適な毎日へ♪
				</p>
				<img src="~@/assets/img/selfcheck-temperature-difference/with-zutool_img.png" alt="" class="growthhack-f__with-zutool--img">
			</div>
			<div class="about-rightnow__login">
				<a v-if="isAndroid" href="dcmdam://launch?url=http%3A%2F%2Fapp-manager.docomo.ne.jp%2FSpApps%2FdetailApp%3Fdcmstore_view%3Dnone%26cId%3D10000023012" class="about-rightnow__login--button" id="trackingSelfcheckAppDownloadBtn01_android">便利なアプリをダウンロード<span>スゴ得ユーザーは使い放題！</span></a>
				<a v-else @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload`, _backPage: 'top' } })" class="about-rightnow__login--button" id="trackingSelfcheckAppDownloadBtn01_ios">便利なアプリをダウンロード<span>スゴ得ユーザーは使い放題！</span></a>
			</div>
			<div class="selfcheck__outro--box">
				<a @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=selfcheckTemperatureDifference` } })" class="selfcheck__outro--back-selfcheck-button">寒暖差疲労セルフチェック<br>にもどる</a>

				<a @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=lp`, _lpType: 'lptemperaturedifference202210' } })" class="selfcheck__outro--back-lptemperaturedifference-button"><img src="~@/assets/img/selfcheck-temperature-difference/banner_202210.png" alt="季節の変わり目は要注意!寒暖差疲労を予防しよう"></a>

				<a @click="$router.push({ name: 'Cpsite', query: { id: sugotokuContentsId } })" class="selfcheck__outro--back-top-button about-rightnow__login--button">TOPにもどる</a>
			</div>
		</section>
		<!-- /アウトロ -->
	</article>
</div>
</template>

<script>
import cmnConst from '@/assets/js/constant.js'

// Vuex
import { mapGetters } from 'vuex'

export default {
	name: 'SelfcheckTemperatureDifferenceRes',
	components: {
	},
	data () {
		return {
			sugotokuContentsId: cmnConst.SUGOTOKU_CONTENTS_ID,
			backendUrl: cmnConst.BACKEND_URL
		}
	},
	props: {
		result: {
			type: String,
			default: ''
		},
		answer: {
			type: String,
			default: ''
		}
	},
	computed: {
		// map Vuex getters
		...mapGetters('common', ['isAndroid'])
	},
	mounted () {
		// コンテンツの高さをemitする処理
		// CHANGED: コンテンツの高さを確保する処理の見直しのためコメントアウト
		// this.$nextTick(() => {
		// 	setTimeout(() => {
		// 		const domContainer = document.getElementById('js_selfcheck_container')
		// 		if (domContainer) this.$emit('containerHeight', domContainer.clientHeight)
		// 	}, 1000)
		// })
	},
	beforeDestroy () {
	},
	methods: {
		// map Vuex actions
	}
}
</script>

<style lang="scss" scoped>
// NOTE: このページ限定の変数（デザインシステム外のため他での利用禁止）
// NOTE: 公式WEBに流用するためvariableのimportはしていません
$lp-family-primary: 'M+ C Type-1 (basic latin) Regular', 'M+ Type-1 (general-j) Regular', sans-serif;
$lp-family-tertiary: 'M+ C Type-1 (basic latin) Bold', 'M+ Type-1 (general-j) Bold', sans-serif;

$spacing-4: 0.25rem;
$spacing-6: 0.375rem;
$spacing-10: 0.625rem;
$spacing-12: 0.75rem;
$spacing-13: 0.812rem;
$spacing-14: 0.875rem;
$spacing-20: 1.25rem;
$spacing-25: 1.5625rem;
$spacing-30: 1.875rem;
$spacing-34: 2.125rem;
$spacing-40: 2.5rem;
$spacing-50: 3.125rem;

$font-size-11: 0.687rem;
$font-size-13: 0.812rem;
$font-size-14: 0.875rem;
$font-size-15: 0.937rem;
$font-size-19: 1.187rem;
$font-size-20: 1.25rem;
$font-size-26: 1.625rem;

$text-primary: #4C4C4C;
$text-seconday: #FFF;
$line-primary: #CCC;
$selfcheck-primary: #FF5400;
$selfcheck-title-border: #F86B25;
$selfcheck-back-background: #21498D;
$selfcheck-back-border: #2091B5;
$background-supervisor: #F5F5F5;

$line-height-13: 1.3;
$line-height-14: 1.4;

.selfcheck {
	padding: 0;
	max-width: 640px;
	margin: 0 auto;

	&__header {
		padding: $spacing-10 $spacing-14 0;
		img {
			display: block;
			width: 100%;
			height: auto;
		}
	}

	&__result {
		width: 92.5334%;
		margin: $spacing-20 auto 0;
		padding: 0 0 $spacing-30;
		font-size: $font-size-15;
		color: $text-primary;
		border-bottom: 1px solid $line-primary;

		&--title {
			margin: 0 auto;
			padding: $spacing-6 $spacing-10;
			text-align: center;
			color: $selfcheck-primary;
			font-size: $font-size-26;
			font-family: $lp-family-tertiary;
			line-height: $line-height-14;
			border-radius: 200px;
			border: 3px solid $selfcheck-title-border;

			@media (max-width: (360px)) { font-size: $font-size-20; }

			&.is-failed { font-size: $font-size-20; }
		}

		&--img {
			img {
				margin: $spacing-4 auto 0;
				display: block;
				width: 100%;
				max-width: 347px;
				height: auto;
			}
		}

		&--text {
			font-family: $lp-family-primary;
			margin: 0;
		}
	}

	&__supervisor {
		width: 92.5334%;
		margin: $spacing-12 auto $spacing-25;
		padding: $spacing-20;
		box-sizing: border-box;
		font-family: $lp-family-primary;
		background-color: $background-supervisor;
		border-radius: 8px;

		&--title {
			display: flex;
			align-items: center;
			margin: 0;

			img {
				width: 72px;
				height: auto;
			}

			& > span {
				display: block;
				margin-left: $spacing-10;
				font-size: $font-size-11;
				font-weight: normal;
			}
			.is-large { font-size: $font-size-14; }
		}

		&--text {
			margin: $spacing-10 0 0;
			font-size: $font-size-13;
			text-align: justify;
		}
	}

	&__outro {
		margin: $spacing-13 0 0;

		&--title {
			margin: 0;
			padding: 0 $spacing-14;
			font-family: $lp-family-primary;
			font-size: $font-size-15;
			font-weight: normal;
		}

		&--box {
			width: 92.5334%;
			margin: $spacing-40 auto 0;
			border-top: 1px solid $line-primary;

			& > a {
				display: block;
				width: 86.4553%;
				margin: $spacing-30 auto 0;
			}
		}

		&--back-selfcheck-button {
			padding: $spacing-10 0;
			box-sizing: border-box;
			background: $selfcheck-back-background;
			box-shadow: 0 3px $selfcheck-back-border;
			border-radius: 6px;
			text-align: center;
			color: $text-seconday;
			font-weight: bold;
			font-size: $font-size-19;
			line-height: $line-height-13;
			pointer-events: auto;
		}

		&--back-lptemperaturedifference-button {
			img {
				display: block;
				width: 100%;
			}
		}

		.growthhack-f__caution { margin: $spacing-50 0 0 0; }
		.about-rightnow__login { padding: 0; }
		.growthhack-f__with-zutool--img { width: 112px; height: auto; }

		&--back-top-button { font-weight: normal; }
	}
}
</style>
